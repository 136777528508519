import React, { useCallback, useEffect } from 'react';
import eventEmitter from 'shared/foreground/eventEmitter';
import { saveDocumentNote } from 'shared/foreground/stateUpdaters/persistentStateUpdaters/documents/anyDocument';
import forwardRef from 'shared/foreground/utils/forwardRef';
import { FirstClassDocument } from 'shared/types';
import { ShortcutId } from 'shared/types/keyboardShortcuts';

import useIsFocused from '../hooks/useIsFocused';
import { useShortcutsMap } from '../utils/shortcuts';
import styles from './DocumentNoteForm.module.css';
import DocumentNoteDropdown from './Dropdown/DocumentNoteDropdown';
import NotebookNoteForm from './NotebookNoteForm';
import Tooltip from './Tooltip';

export type Props = {
  className?: string;
  copyNote: () => void;
  deleteNote: () => void;
  doc: FirstClassDocument;
  focusPseudoUnit: () => void;
};

export default React.memo(
  forwardRef<Props, HTMLTextAreaElement>(function DocumentNoteForm(
    { className, copyNote, deleteNote, focusPseudoUnit, doc },
    ref,
  ) {
    const shortcutsMap = useShortcutsMap();
    const { isFocused, onBlur, onFocus } = useIsFocused();
    const focus = useCallback(() => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      if (isFocused || !ref.current) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const textarea = ref.current.querySelector('textarea') as HTMLTextAreaElement | null;

      if (!textarea) {
        throw new Error("Can't find textarea from document note form element");
      }

      textarea.focus();
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
    }, [isFocused, ref]);

    useEffect(() => {
      eventEmitter.on('focus-doc-note', focus);
      return () => {
        eventEmitter.off('focus-doc-note', focus);
      };
    }, [focus]);

    const updateNote = useCallback(
      (newNote: string) => {
        saveDocumentNote(doc.id, newNote, { userInteraction: 'unknown' });
      },
      [doc.id],
    );

    const rootClasses = [styles.documentNoteForm, className].filter(Boolean);

    return (
      <div className={rootClasses.join(' ')}>
        <Tooltip
          aria-label="Document note"
          content="Add a document note"
          disabled={Boolean(doc.notes ?? isFocused)}
          shortcut={shortcutsMap[ShortcutId.AddDocNote]}
        >
          <NotebookNoteForm
            focusPseudoUnit={focusPseudoUnit}
            note={doc.notes}
            onBlur={onBlur}
            onFocus={onFocus}
            onSave={updateNote}
            placeholder="Add a document note..."
            ref={ref}
            shouldShowIfEmpty
            textareaClassNames={{
              textarea: styles.field,
              wrapper: styles.fieldWrapper,
            }}
          />
        </Tooltip>
        <DocumentNoteDropdown
          buttonClassName={styles.actionsMenuButton}
          copyNote={copyNote}
          deleteNote={deleteNote}
          note={doc.notes}
          onEscapeKeyDown={focusPseudoUnit}
        />
      </div>
    );
  } as React.FC<Props>),
);
